import { database, auth } from 'firebase_config'

export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';

const users = database.ref('users/users');
const settings = database.ref('repair_work/settings');

// =================================
// ========Settings Actions=========
// =================================

export function fetchSettings() {
    return dispatch => settings.on('value', snapshot => {
        dispatch({
            type: FETCH_SETTINGS,
            payload: snapshot ? snapshot.val() : {}
        })
    })
}

export function addThread(values, cb) {
    return () => settings.child('threads').push(values).then(cb);
}

export function editThread(id, values, cb) {
    return () => settings.child(`threads/${id}`).set(values).then(cb);
}

export function deleteThread(id, cb) {
    return () => settings.child(`threads/${id}`).remove().then(cb);
}
// =================================
// ==========Auth Actions===========
// =================================

export function signIn(values, cb) {
    const { email, password } = values;
    return () => auth.signInWithEmailAndPassword(email, password)
    .then(res=>{
            cb({type: 'User', res});
            })
        .catch(res=>cb({type: 'Error', res}))
}


export function verifyAuth() {
    return dispatch=>{
        const key = 'firebase:authUser:AIzaSyCWm0ZmQfgmqEqGuFFQqfkewU58d2V-14c:[DEFAULT]';
        auth.onAuthStateChanged(user=>{
            if (user) {
                users.child(user.uid).once('value').then(snapshot=>{
                    const dbUser = snapshot.val()
                    const userString = JSON.stringify(user)
                    window.localStorage.setItem(key, userString)
                    console.log('logged in')
                    dispatch({
                        type: AUTH_USER,
                        payload: {...user, ...dbUser}
                    })
                })
                
            } else {
                window.localStorage.removeItem(key)
                dispatch({
                    type: UNAUTH_USER
                })
            }
        });
    };
}


