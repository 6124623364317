import { database } from 'firebase_config';

export const FETCH_ALL_CUSTOMERS = 'FETCH_ALL_CUSTOMERS';

const customers = database.ref('customers/customers');

export function fetchAllCustomers() {
  return dispatch => customers.once('value', snapshot => {
    dispatch({
      type: FETCH_ALL_CUSTOMERS,
      payload: snapshot ? snapshot.val() : {}
    });
  });
}
