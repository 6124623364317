import { map } from 'lodash';
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemText,
  Fab,
  Chip,
  Slide,
  Box
} from '@material-ui/core';
import { Add } from '@material-ui/icons'

import Jobs from 'components/jobs';
import SearchCustomerJobs from 'components/SearchCustomerJobs';

import * as actions from 'actions/customerActions';

const SlideComponent = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <Jobs  customer={props.customer} />
  </div>
  ))

const useStyles = makeStyles(theme => {
  return ({
  root: {
    position: 'relative',
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1)
  },
  list: {
    minHeight: 80,
    maxHeight: 'calc(95vh - 158px)',
    overflow: 'auto'
  }
})});

const Customers = ({
  customers,
  fetchAllCustomers
}) => {
  const classes = useStyles();
  const [customer, setCustomer] = useState({})
  const [slide, setSlide] = useState(false);
  useEffect(() => {
    const fetch = () => fetchAllCustomers();
    fetch();
    }, [fetchAllCustomers])
  return (
    <div className={classes.root}>
        {customer.name
        ? <React.Fragment> 
            <Chip
              label={customer.name}
              onDelete={() => {
                setSlide(false);
                setTimeout(() => setCustomer({}), 275);
              }}
            />
            <Slide in={slide} direction="down"> 
              <Box>
                <SearchCustomerJobs customer={customer}/>
                <SlideComponent customer={customer}/>
              </Box>
            </Slide>
          </React.Fragment> 
      : <List className={classes.list}>
          {map(customers, (customer, customerId) => {
            const { name, location } = customer;
            return (
              <ListItem key={customerId} button onClick={() => {
                setCustomer({name, id: customerId});
                setTimeout(() => setSlide(true), 275);
              }}>
                <ListItemText
                  primary={name}
                  secondary={location}
                />
              </ListItem>
            )
          })}
        <Fab
          className={classes.fab}
          color="primary"
        >
          <Add />
        </Fab>
        </List>
        }
    </div>
  )
};

function mapStateToProps({ customers }) {
  return {
    customers
  }
}

export default connect(mapStateToProps, actions)(Customers);

