import { map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';

import * as jobActions from 'actions/jobActions';
import * as customerActions from 'actions/customerActions';

const actions ={
  ...jobActions,
  ...customerActions
};

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2)
  }
}))

const NewJob = ({
  open,
  onClose,
  jobId,
  edit,
  fetchAllCustomers,
  createJob,
  editJob,
  customers
}) => {
  const classes = useStyles();
  React.useEffect(() => {
    const fetch = () => fetchAllCustomers();
    fetch()
  }, [fetchAllCustomers])
  return (
    <Formik
      enableReinitialize={true}
      initialValues={edit || {
        customer: '',
        workOrder: '',
        packageNumber: '',
        status: 'open'
      }}
      onSubmit={
        (values, { resetForm }) => {
        const cb = () => {
            resetForm();
            onClose();
        }
        edit
        ? editJob(jobId, values, cb)
        : createJob(values, cb)
      }}
      validationSchema={
        object().shape({
          customer: string().required('A work order is required'),
          workOrder: string().required('A customer is required')
        })
      }
    >
      {({
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        resetForm
      }) => {
        const close = () => {
          onClose();
          resetForm();
        }
        return (
          <Dialog open={open} onClose={close}>
            <Form>
              <DialogTitle>{
                edit
                ? 'Update Maintenance Ticket'
                : 'Create a Maintenance Ticket'
                }</DialogTitle>
              <DialogContent>
                <TextField
                  name="workOrder"
                  label="Work Order"
                  variant="outlined"
                  className={classes.button}
                  fullWidth
                  value={values.workOrder}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.workOrder && errors.workOrder)}
                  helperText={touched.workOrder && errors.workOrder}
                />
                <TextField
                  name="packageNumber"
                  label="Package Number"
                  variant="outlined"
                  className={classes.button}
                  fullWidth
                  value={values.packageNumber || ''}
                  onChange={handleChange}
                />
                <TextField
                  name="customer"
                  select
                  label="Customer"
                  variant="outlined"
                  className={classes.button}
                  fullWidth
                  value={values.customer}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.customer && errors.customer}
                  helperText={touched.customer && errors.customer}
                >
                  <MenuItem value="" />
                  {map(customers, (customer, customerId) => {
                    return <MenuItem key={customerId} value={customer.name}>{customer.name}</MenuItem>
                  })}
                </TextField>
              </DialogContent>
              <DialogActions>
                <Button onClick={close}>Cancel</Button>
                <Button type="submit" color="primary">
                  {edit
                    ? 'Update'
                    : 'Create'
                  }
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        )
      }}
      </Formik>
    );
};

function mapStateToProps({ customers }) {
  return {
    customers
  }
}
export default connect(mapStateToProps, actions)(NewJob);
